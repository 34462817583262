import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Tables are used to organize and display data efficiently. The data table component allows for customization with additional functionality, as needed by your product’s users`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/table/guideline-website-1.svg",
      "alt": "table anatomy guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Header cell :`}</strong>{` input field for header text is the first row across all columns.`}</li>
      <li parentName="ol"><strong parentName="li">{`Body text :`}</strong>{` content related to its corresponding column header.`}</li>
      <li parentName="ol"><strong parentName="li">{`Header text :`}</strong>{` title or concise description of the content within its column.`}</li>
      <li parentName="ol"><strong parentName="li">{`Body cell :`}</strong>{` input field for body text can contain alphanumeric text or links.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Tables are great at presenting a clear structure that can be scanned to get just the right information. Make sure to use all of the benefits this structure offers. When using tables you need to consider the structure of the data you want to display and organize that information into columns. Columns will typically have column headers. Every row within a table must have a consistent format. If the table row includes actions, they should always be placed in the rightmost column.`}</p>
    <p><strong parentName="p">{`Common places buttons appear :`}</strong>{` Content area ,Data Table etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> To present tabular information with a clear, repeating structure.</li>
      <li> To help users scan structured data to make informed decisions. </li>
      <li> Display tabular data for comparison. </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> When you have fewer sections and want to emphasize each more </li>
      <li> For simple, non-tabular information </li>
      <li> Create page layout or design structure.</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`When using tables you need to consider the structure of the data you want to display and organize that information into columns. Columns will typically have column headers. Every row within a table must have a consistent format. If the table row includes actions, they should always be placed in the rightmost column(s).Tables are great for presenting information with a clear, repeating structure. Breaking structured data into tables helps users scan it for what they need.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="DO" caption="Similar to a paragraph of text, textual data is always left-aligned, numerical data should generally be right-aligned" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/table/guideline-website-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="DON'T" caption="Not used left-aligned for text,  textual data and not be right-aligned for numerical data inside the column" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/table/guideline-website-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating tabel :`}</p>
    <ul>
      <li parentName="ul">{`The table title should make it clear to the user what this the data has in common and what purpose it serves in the UI`}</li>
      <li parentName="ul">{`A data table’s title and description should use sentence-case capitalization.`}</li>
      <li parentName="ul">{`A description can be added under the title to provide more information about the data or its source.`}</li>
      <li parentName="ul">{`Column titles should stick to one or two words that describe the data in that column`}</li>
      <li parentName="ul">{`Column titles should use sentence-case capitalization.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      